<template>
  <div>
    <div class="maintitle">类型管理</div>
    <el-divider></el-divider>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-select v-model="crntdistrict" placeholder="请选择地区" style="width:120px;">
          <el-option
            v-for="(item,index) in district"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="getlist">查找</el-button>
      </el-col>
    </el-row>
    <el-table
      :data="list"
      row-key="id"
      border
      lazy
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="type" label="类型" width="180"></el-table-column>
      <el-table-column label="状态" width="100" align="center">
        <template slot-scope="scope">
          <span v-if='scope.row.status == 1'>正常</span>
          <span v-else>已禁用</span>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格(元)" width="100" align="center"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              size="small"
              @click="changestatus(scope)"
              v-if="scope.row.status == 1"
            >停用</el-button>
            <el-button size="small" @click="changestatus(scope)" v-else>启用</el-button>
            <el-button size="small" v-if="scope.row.pid == 0 && scope.row.status ==1" @click="addtype(scope)">添加子类</el-button>
            <el-button size="small" v-if="scope.row.pid != 0" @click="mdfy(scope)">修改</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="addTypeDiv">
      <el-form :model="mdfytype" :rules="rules" ref="mdfytype">
        <el-form-item
          label="大分类"
          v-if="act == 'add'"
          :label-width="formLabelWidth"
          class="text-left"
        >{{crntPTypeName}}</el-form-item>
        <el-form-item label="名称" :label-width="formLabelWidth" prop="type">
          <el-input v-model="mdfytype.type" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="回收价格" :label-width="formLabelWidth" prop="price">
          <el-input v-model="mdfytype.price" autocomplete="off" type="number"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addTypeDiv = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //类型列表
      addTypeDiv: false, //添加类型窗口
      mdfytype: {
        type: "", //编辑的子类型名称
        price: 0, //子类型价格
        pid: 0, //子类型的父类型
        id: 0,
        district: 0
      },
      crntPTypeName: "", //子类型的父类型名称
      act: "add",
      formLabelWidth: "150px",
      crntscope: [], //当前编辑对象
      rules: {
        type: [
          { required: true, message: "请输入子分类名称", trigger: "blur" },
          { min: 2, message: "长度不能小于两个汉字", trigger: "blur" }
        ],
        price: [{ required: true, message: "请输入回收价格", trigger: "blur" }]
      },
      crntdistrict: "", //当前选择县/区
      district: [] //县/区列表
    };
  },
  computed: {},
  methods: {
    getlist: function() {
      this.$axios({
        url: this.HOST + "/jiameng/goodstype/getTypeList",
        method: "post",
        data: {
          district: this.crntdistrict
        }
      }).then(res => {
        let temp = [];
        for (var i in res.data) {
          temp.push(res.data[i]);
        }
        this.list = temp;
      });
    },
    changestatus(scope) {
      let url = '';
      if(scope.row.level == 1){
        url = this.HOST + "/jiameng/goodstype/setTypeStatus";
      }else{
        url = this.HOST + "/jiameng/goodstype/setGoodsTypeStatus";
      }
      let status = scope.row.status;
      status = status == 1 ? 2 : 1;
      let str = status == 1 ? "启用" : "停用";
      this.$confirm("确定要" + str + scope.row.type + "吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let param = {
            id: scope.row.id,
            status: status,
            district:scope.row.district
          };
          this.$axios({
            method: "post",
            url: url,
            data: param
          }).then(res => {
            if (res.data.status == 1) {
              scope.row.status = status;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    add() {
      this.mdfytype.district = this.crntdistrict;
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/goodstype/addtype",
        data: {
          info: this.mdfytype
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.addTypeDiv = false;
          if (this.act == "add") {
            this.getlist();
          } else {
            this.crntscope.price = this.mdfytype.price;
            this.crntscope.type = this.mdfytype.type;
          }
        }
      });
    },
    addtype(scope) {
      if (this.$refs.mdfytype !== undefined) {
        this.$refs.mdfytype.resetFields();
      }
      this.mdfytype.type = "";
      this.mdfytype.pid = scope.row.id;
      this.crntPTypeName = scope.row.type;
      this.mdfytype.price = 0;
      this.mdfytype.id = 0;
      this.addTypeDiv = true;
      this.act = "add";
    },
    mdfy(scope) {
      if (this.$refs.mdfytype !== undefined) {
        this.$refs.mdfytype.resetFields();
      }
      this.crntscope = scope.row;
      this.mdfytype.type = scope.row.type;
      this.mdfytype.pid = scope.row.pid;
      this.mdfytype.price = scope.row.price;
      this.mdfytype.id = scope.row.id;
      this.addTypeDiv = true;
      this.act = "mdfy";
    },
    slctprovince() {
      this.getAreaList(this.crntprovince, "c");
      this.crntcity = "";
      this.crntdistrict = "";
    },
    slctcity() {
      this.getAreaList(this.crntcity, "co");
    },
    getAreaList() {
      let url = this.HOST + "/jiameng/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.district = res.data.list;
        } else {
          return [];
        }
      });
    }
  },
  created: function() {
    this.getAreaList();
  }
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>